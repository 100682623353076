import * as React from "react"
import { useState } from "react"
import { Slide } from 'react-slideshow-image'

//On importe le CSS


//On importe les SVG
import Trophee from '../images/trophee.svg'

export default function Resultats() {



    const layer1 = [
        {
            titre: "RELAXE STUPÉFIANTS",
            juridiction: "Tribunal Correctionnel de Dunkerque",
            date: "7 septembre 2021",
            short: "Relaxe de l’infraction de conduite après usage de produits stupéfiants avec remboursement des frais d’avocat hauteur de 1213,00 €.",
            texte: "<p>Cette affaire illustre parfaitement qu’avec les bons conseils, il est régulièrement possible d’éviter la condamnation en matière de conduite après usage de produits stupéfiants.</p><p>Le client avait fait l’objet d’un dépistage salivaire, puis d’un prélèvement à l’issue duquel il avait souhaité se réserver la possibilité d’une seconde analyse par prélèvement sanguin (cf. article sur ce point (si possible mettre un lien vers l’article avec le fameux formulaire). Les forces de l’ordre ont ensuite commis plusieurs erreurs qui compromettaient irrémédiablement la procédure, qui aurait donc dû faire l’objet d’un classement sans suite de la part du Procureur de la République.</p><p>Après avoir fait droit à nos conclusions de nullité, le Tribunal accorda fort logiquement une indemnité de procédure au client d’un montant de 1213,00 €, le remboursant donc intégralement de ses frais d’avocat.</p>"
        },
        {
            titre: "RELAXE ALCOOL",
            juridiction: "Tribunal Correctionnel de Boulogne-sur-Mer",
            date: "21 septembre 2021",
            short: "Relaxe de l’infraction de conduite en état d’ivresse manifeste.",
            texte: "<p>Les faits paraissaient pourtant accablants : une perte de contrôle avec un véhicule sportif qui finissait par détruire un véhicule en stationnement. Problème, le prévenu n’était en fait pas ivre, et la perte de contrôle n’était pas dû à l’alcool mais à un problème mécanique sur le véhicule.</p><p>En matière de conduite en état d’ivresse manifeste, le Tribunal doit disposer de suffisamment d’éléments pour caractériser sans le moindre doute ladite ivresse, à défaut, le doute doit profiter au prévenu qui doit être relaxé.</p><p>En dépit des constatations des policiers, le Tribunal a non seulement relaxé le client, mais il a en plus débouté intégralement la partie civile de ses demandes de dommages et intérêts.</p>"
        },
        {
            titre: "RELAXE REFUS D'OBTEMPERER",
            juridiction: "Tribunal Correctionnel de Boulogne-sur-Mer",
            date: "3 novembre 2021",
            short: "Relaxe de l’infraction de refus d’obtempérer à une sommation de s’arrêter.",
            texte: "<p>Là encore, les faits paraissent accablants : le prévenu passe en voiture à côté des gendarmes qui sont postés sur un rond-point et accélère fortement à la première sortie. Le procès-verbal des gendarmes est dénué d’ambiguïté : le conducteur a voulu échapper au contrôle en n’obtempérant pas aux sommations de s’arrêter.</p><p>En réalité, le prévenu avait mal compris l’indication du gendarme et a cru qu’il lui avait demandé de dégager le rond-point vers la droite. Par ailleurs, il s’agissait de gendarmes réservistes qui n’avaient pas nécessairement qualité pour constater ce type d’infractions. Enfin, ces derniers s’abstenaient de poursuivre le client, qui n’avait donc aucun moyen de savoir que des gendarmes voulaient l’intercepter.</p><p>Le Tribunal a donc relaxé le client en dépit des déclarations peu convaincantes des gendarmes.</p>"
        },
        {
            titre: "RELAXE STUPÉFIANTS",
            juridiction: "Tribunal Correctionnel de Cambrai",
            date: "17 décembre 2021",
            short: "Relaxe de l’infraction de conduite après usage de produits stupéfiants.",
            texte: "<p>Cette affaire est particulièrement symptomatique de tout ce que j’indiquais par ailleurs sur le site au sujet du comportement parfois très contestable des forces de l’ordre.</p><p>Les gendarmes avaient cru pouvoir signer à la place du client un document essentiel. Cette fausse signature aurait pu passer totalement inaperçue si le prévenu n’avait pas mandaté un avocat pour accéder à la procédure. Lors de sa lecture, je constatais une signature censée être celle du client légèrement différent des autres.  Le client me confirmait qu’il ne s’agissait pas de sa signature, je décidais donc de solliciter avant dire droit une expertise en écritures devant le Tribunal Correctionnel.</p><p>Le Tribunal devait faire droit à cette demande, et l’expert judiciaire concluait qu’il ne s’agissait pas de la signature de mon client.</p><p>J’ai donc sollicité l’annulation de l’entière procédure qui fut accordée par le Tribunal Correctionnel. Oui, il peut arriver aux forces de l’ordre de mentir, et même de commettre des faux en écritures. Non, il ne faut pas avoir peur de le dire si cela est fait avec sérieux et méthode.</p>"
        },
        {
            titre: "PERMIS RECUPÉRÉ",
            juridiction: "Ministère de l'intérieur",
            date: "15 décembre 2021",
            short: "Permis restitué en dépit d’un recours hors délai.",
            texte: "<p>Lorsque le titulaire d’un permis de conduire reçoit un courrier qui l’informe de l’invalidation de son permis de conduire pour cause de solde de point nul (la fameuse lettre 48SI), il dispose d’un délai de 2 mois pour la contester.</p><p>Dans cette affaire, à cause d’une erreur du Tribunal, le client s’était vu retirer 10 points au lieu de 8.</p><p>En dépit d’un recours formé plus de 8 mois après l’invalidation du permis de conduire, le cabinet obtenait gain de cause et le client a retrouvé un permis de conduire valable sans aucune formalité à accomplir.</p>"
        },
        {
            titre: "CONTRAVENTIONS ANNULÉES",
            juridiction: "Tribunal de Police",
            date: "6 juillet 2021",
            short: "Relaxe de 8 contraventions au code de la route .",
            texte: "<p>Le client a reçu pas moins de 8 contraventions qui auraient été commises le même jour au même moment sans avoir fait l’objet de la moindre verbalisation sur place. Il lui était donc tout à fait possible de prétendre qu’il ne se trouvait pas au volant et échapper ainsi à sa responsabilité pénale, mais il aurait dû en contrepartie payer de lourdes amendes sur le fondement de la responsabilité pécuniaire en sa qualité de titulaire du certificat d’immatriculation.</p><p>Après une analyse approfondie de la situation, il a été décidé de ne pas contester la qualité de conducteur et de remettre en cause la véracité des constatations de l’agent verbalisateur.</p><p>C’est une stratégie qu’il n’est pas toujours possible d’utiliser, car l’article 537 du Code de Procédure Pénale impose de rapporter la preuve contraire par écrits ou témoins, ce qui est quasi impossible.</p><p>En l’espèce, nous ne disposions de toute façon d’aucun témoin. Aux termes d’une argumentation très technique, le tribunal a néanmoins fait droit à notre argumentation et relaxé le prévenu pour les 8 infractions.</p>"
        },
    ]

    //Avoir d'autres résultats quand on slide
    // const layer2 = [
    //     {
    //         titre: "3PERMIS RÉCUPÉRÉ",
    //         date: "16/03/2021",
    //         commentaire: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus at diam dapibus, accumsan mi vestibulum, vestibulum odio. Nulla iaculis et urna sit amet luctus. Ut ornare fermentum erat eu vestibulum. Donec eget quam lacus. Proin vehicula congue interdum. Fusce maximus enim id turpis facilisis efficitur"
    //     },
    //     {
    //         titre: "4RELAXE STUPÉFIANTS",
    //         date: "17/03/2021",
    //         commentaire: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus at diam dapibus, accumsan mi vestibulum, vestibulum odio. Nulla iaculis et urna sit amet luctus. Ut ornare fermentum erat eu vestibulum. Donec eget quam lacus. Proin vehicula congue interdum. Fusce maximus enim id turpis facilisis efficitur"
    //     }
    // ]


    const fadeProperties = {
        duration: 5000,
        pauseOnHover: true,
        arrows: true,
        prevArrow: <div style={{ width: "30px", marginRight: "-30px" }}></div>,
        nextArrow: <div style={{ width: "30px", marginRight: "-30px" }}></div>
        // prevArrow: <div style={{ width: "30px", marginRight: "-30px" }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#00000"><path d="M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12    C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084    c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864    l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z" /></svg></div>,
        // nextArrow: <div style={{ width: "30px", marginLeft: "-30px" }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#00000"><path d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12    c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028    c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265    c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z" /></svg></div>
    }


    const [clic, setClic] = useState(false);

    console.log(clic)

    return (

        <div className="MainResultat" id="resultat">

            <div className="ContainerResultat">

                <img src={Trophee} alt="Trophée" width="50px" />

                <h2>LES RÉSULTATS</h2>

            </div>

            <div>
                <div className="slide-container">
                    <Slide {...fadeProperties}>



                        {
                            layer1.map((item, i) =>

                                <div className="each-slide ContainerSlideResultat" key={i}>

                                    <div className="ContainerTitreResultat">
                                        <div className="ItemTitreResultat">
                                            <h4 align="center">{item.titre}</h4>
                                            {
                                                clic ? <button onClick={() => setClic((setClic) => !setClic)}>Masquer le résumé...</button> : <button onClick={() => setClic((setClic) => !setClic)}>Afficher le résumé...</button>
                                            }
                                            

                                        </div>
                                        <div className="ItemTitreResultat">
                                            <p id="short" align="center">{item.short}</p>
                                            <p id="juridiction" align="center">{item.juridiction} - {item.date}</p>


                                        </div>
                                    </div>

                                    {
                                        clic ? <p align="justify" dangerouslySetInnerHTML={{ __html: item.texte }}></p> : ""
                                    }



                                </div>

                            )
                        }


                        {/* {
                            layer2.map((item, i) =>

                                <div className="ContainerResultat" key={i}>


                                    <div className="each-fade">

                                        <p className="TitreResultat">{item.titre}</p>


                                        <p className="DateResultat">{item.date}</p>

                                    </div>

                                    <p align="justify">{item.commentaire}</p>

                                </div>

                            )
                        } */}


                    </Slide>
                </div>
            </div>


        </div>




    )
}