import * as React from "react"
import { Helmet, HelmetProvider } from 'react-helmet-async'



//On importe les composants
import Header from '../components/header'
import BandeauAccueil from '../components/bandeau-accueil'
import FirstDomaines from '../components/first-domaines'
import Presentation from '../components/presentation'
import Resultats from '../components/resultats'
import Vocation from '../components/vocation'
import AllDomaines from '../components/all-domaines'
import Adresse from '../components/adresse'
import Footer from '../components/footer'

//On importe les images
import Favicon from '../images/favicon.jpg'

//On importe le CSS
import'../css/main.scss'
import '../css/footer.scss'
import '../css/adresse.scss'
import '../css/article.scss'
import '../css/bandeau-accueil.scss'
import '../css/bandeau-contact.scss'
import '../css/bandeau-page.scss'
import '../css/domaines.scss'
import '../css/formulaire.scss'
import '../css/header.scss'
import '../css/presentation.scss'
import '../css/resultats.scss'
import '../css/tableau.scss'

export default function IndexPage (){


  
  return(
    <HelmetProvider>
      <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>DEBRABANT Avocat Permis de conduire à Boulogne-sur-Mer</title>
        <meta name="description" content="Véritable expert en droit du Permis de Conduire, Maître Thierry DEBRABANT, Avocat gère vos problématiques en matière d'excès de vitesse, retrait ou annulation de permis, alcool au volant ou encore stupéfiants au volant." />
        <html lang="fr" />
        <link rel="icon" href={Favicon} />
        <link rel="canonical" href="https://debrabant-avocat.com" />

        <meta property='og:title' content='DEBRABANT Avocat Permis de conduire à Boulogne-sur-Mer' />
        <meta property='og:image' content='' />
        <meta property='og:description' content="Cabinet d'Avocat expert en droit du Permis de Conduire : Excès de vitesse, retrait ou annulation de permis, alcool au volant ou encore stupéfiants au volant." />
        <meta property='og:url' content='https://debrabant-avocat.com' />
      </Helmet>

      <Header/>

      <BandeauAccueil/>

      <FirstDomaines/>

      <Resultats/>

      <Presentation/>

      <Vocation/>

      <AllDomaines/>

      <Adresse/>

      <Footer/>
      

    </HelmetProvider>
  )
}
