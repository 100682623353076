import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

//On importe le css


//On importe les Hooks
import { useBandeauAccueil } from '../hooks/usebandeauaccueil'

export default function BandeauAccueil() {

    const image = getImage(useBandeauAccueil())



    return (


        <div className="BackGreyAccueil">

            <h1>PRÉSERVER VOS DROITS, PROTÉGER VOTRE PERMIS DE CONDUIRE</h1>


            <div className="BandeauMain">

                <div className="BandeauContainer">
                    <GatsbyImage image={image} alt="" />
                </div>

                <div className="BandeauContainer">

                    <p align="justify">Perdre votre permis de conduire, c'est perdre votre liberté, <b>mais aussi parfois votre travail</b>.</p>
                    <p align="justify">Le rôle de l’Avocat est d'être le <b>garde-fou</b> entre une répression qui s'intensifie et le respect de vos droits fondamentaux.</p>
                    <p align="justify"><b>J'étudie chaque dossier</b> pour vérifier si je peux supprimer la sanction qui pèse sur vous.</p>
                    <p align="justify">Je n'ai qu'une ambition, <b>protéger votre permis</b> de conduire et votre responsabilité pénale.</p>

                </div>

            </div>

            <p id="BoutonRdv"align="center"><a href="/contact">Prendre RDV</a></p>


        </div>

    )
}