import { useStaticQuery, graphql } from "gatsby"


export const usePhotoPresentation = () => {
  const { file } = useStaticQuery(
    graphql`
    query QueryPhotoPresentation {
        file(relativePath: {eq: "bureau-debrabant-avocat.jpg"}) {
          childImageSharp {
            gatsbyImageData(
              width:700
                placeholder: BLURRED
                layout: FULL_WIDTH
                formats: [AUTO, WEBP, AVIF]
              )
          }
        }
      }
      
    `
  )
  return file
}