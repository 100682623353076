import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


//On importe le CSS


//On importe les Hooks
import { usePhotoVocation } from '../hooks/usephotovocation'


export default function Vocation() {

    const image = getImage(usePhotoVocation())

    return (

        <div className="BackJaune">
            <div className="MainPresentation">



                <div className="ContainerPresentation">

                    <GatsbyImage image={image} alt="Bureau Thierry Debrabant" />

                    <p align="center" id="legende"><i>Thierry DEBRABANT, Avocat à Boulogne-sur-Mer</i></p>


                </div>

                <div className="ContainerPresentation">

                    <h2 align="center">MA VOCATION</h2>
                    <p align="justify">Je ne sais pas si on peut parler de vocation, mais au Lycée j'ai éprouvé le besoin d'être délégué de classe.</p>
                    <p align="justify">Inconsciemment, je glissais systématiquement un commentaire favorable pour chaque élève. Mes professeurs me disaient <b>"M. DEBRABANT, c'est un Conseil de Classe, pas un Tribunal ici"</b>.</p>
                    <p align="justify">Pour autant, je ne savais pas encore quel métier je voulais exercer. Ce qui est sûr, c'est que j'ai toujours eu la <b>passion de l’automobile</b> et des sports mécaniques.</p>
                    <p align="justify">Mon père était mécanicien-auto et m’a fait baigner depuis mon enfance dans cet univers qui ne se résume pas à un assemblage de pièces.</p>
                    <p align="justify">L’automobile, c’est avant tout une porte vers la liberté, <b>mais aussi bien souvent un outil de travail indispensable</b>.</p>

                </div>

            </div>

        </div>
    )
}