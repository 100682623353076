import { useStaticQuery, graphql } from "gatsby"


export const useBandeauAccueil = () => {
  const { file } = useStaticQuery(
    graphql`
    query MyQuery {
        file(relativePath: {eq: "dessin-voiture.jpg"}) {
          childImageSharp {
            gatsbyImageData(
              width:200
                placeholder: BLURRED
                layout: FULL_WIDTH
                formats: [AUTO, WEBP, AVIF]
              )
          }
        }
      }
      
    `
  )
  return file
}