import * as React from "react"
import { Link } from "gatsby"

//On importe le CSS


//On importe les SVG
import AlcoolVolant from '../images/alcool-au-volant.svg'
import CompteurVitesse from '../images/compteur-de-vitesse.svg'
import PermisConduire from '../images/permis-de-conduire.svg'
import StupefiantVolant from '../images/stupefiants-au-volant.svg'

export default function FirstDomaines() {

    const Domaines = [
        {
            titre: "Excès de vitesse",
            lien: "/exces-de-vitesse",
            src: CompteurVitesse        },
        {
            titre: "Retrait / Annulation de permis",
            lien: "/retrait-annulation-de-permis",
            src: PermisConduire        },
        {
            titre: "Alcool au volant",
            lien: "/alcool-au-volant",
            src: AlcoolVolant        },
        {
            titre: "Stupéfiants au volant",
            lien: "/stupefiants-au-volant",
            src: StupefiantVolant        }
    ]

    return (

        <div className="MainFirstDomaine">

            <h2 align="center">FAITES RESPECTER VOS DROITS</h2>

            <div className="ContainerFirstDomaine">

                {
                    Domaines.map((item, i) => {

                        return (

                            <Link to={item.lien} title={item.titre} className="ItemDomaine" key={i}>

                                    <img src={item.src} alt={item.titre} />

                                    <h3 align="center">{item.titre}</h3>

                            </Link>


                        )

                    })
                }

            </div>

        </div >
    )
}