import * as React from "react"

//On importe le CSS


//On importe les SVG
import Conducteur from '../images/conducteur.svg'
import Annuler from '../images/annuler.svg'
import Permis from '../images/permis.svg'
import Accident from '../images/accident.svg'
import Smartphone from '../images/smartphone.svg'
import Tribunal from '../images/tribunal.svg'
import Points from '../images/points.svg'
import Justice from '../images/justice.svg'



export default function AllDomaines() {

    const Domaines = [
        {
            titre: "Non dénonciation de conducteur",
            lien: "#",
            src: Conducteur      
        },
        {
            titre: "Lettre 48 SI",
            lien: "#",
            src: Annuler     
        },
        {
            titre: "Conduite sans permis de conduire",
            lien: "#",
            src: Permis        
        },
        {
            titre: "Accident de la route",
            lien: "#",
            src: Accident        
        },
        {
            titre: "Téléphone au volant",
            lien: "#",
            src: Smartphone        
        },
        {
            titre: "CRPC",
            lien: "#",
            src: Tribunal        
        },
        {
            titre: "Retrait de points",
            lien: "#",
            src: Points        
        },
        {
            titre: "Droit pénal général",
            lien: "#",
            src: Justice        
        }
    ]

    return (

        <div className="MainFirstDomaine">

            <h2 align="center">DOMAINES D'INTERVENTION</h2>

            <div className="ContainerFirstDomaine">

                {
                    Domaines.map((item, i) => {

                        return(

                            item.lien === "#" ?


                                <div className="ItemDomaine" key={i}>
    
                                        <img src={item.src} alt={item.titre} />
    
                                        <h3 align="center">{item.titre}</h3>
    
                                </div>
                            

                                :

                     
                                    <a href={item.lien} title={item.titre} className="ItemDomaine" key={i}>
        
                                            <img src={item.src} alt={item.titre} />
        
                                            <h3 align="center">{item.titre}</h3>
        
                                    </a>

                        )

                        
                    })
                }

            </div>

        </div >
    )
}