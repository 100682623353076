import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


//On importe le CSS


//On importe les Hooks
import { usePhotoPresentation } from '../hooks/usephotopresentation'


export default function Presentation() {

    const image = getImage(usePhotoPresentation())

    return (

        <div className="BackGrey">
            <div className="MainPresentation">

                <div className="ContainerPresentation">

                    <h2 align="center">AVOCAT DU PERMIS</h2>
                    <p align="justify">J'exerce la profession d'Avocat <b>depuis 2005</b>, j'ai pu au cours de ces <b>19 années d'expérience</b> obtenir des <b>centaines d'annulations de procédures</b>, de relaxes, de dispenses de peines, ou de <b>récupérations de permis</b> de conduire annulés ou suspendus.</p>
                    <p align="justify"><b>J'étudie chaque dossier</b> avec la rigueur la plus absolue pour vérifier si je peux <b>supprimer</b> ou alléger la sanction encourue.</p>
                    <p align="justify">Une sélection des décisions que j'ai obtenues est <b>consultable</b> directement en bas de cette page afin que vous puissiez vous rendre compte par vous-même de l'excellence et de la réalité des résultats</p>

                </div>

                <div className="ContainerPresentation">

                    <GatsbyImage image={image} alt="Bureau Thierry Debrabant" />

                    <p align="center" id="legende"><i>Thierry DEBRABANT, Avocat à Boulogne-sur-Mer</i></p>


                </div>

            </div>

        </div>
    )
}